<template>
  <div class="l-wrapper">
    <div class=" q-pa-md">
      <q-btn
        unelevated
        class="full-width q-mb-sm"
        color="grey-8"
        label="Filter"
        @click="
          tempFilter = JSON.parse(JSON.stringify(options));
          showFilter = !showFilter;
        "
      ></q-btn>
      <q-input
        outlined
        label="Cari Nama Perusahaan"
        v-model="options.searchTerm"
        @update:model-value="filteringCostumer"
      ></q-input>
    </div>
    <q-scroll-area class="l-grow bg-grey-2">
      <q-list>
        <q-item
          class="bg-grey-4"
          clickable
          v-for="(val, i) in filteredCostumer"
          :key="i"
          v-ripple
        >
          <q-item-section>
            <q-item-label>{{ val.name }}</q-item-label>
            <q-item-label caption
              >{{ val.city }} | {{ val.sales_name }}</q-item-label
            >
          </q-item-section>

          <q-item-section side>
            <q-btn-dropdown flat color="primary" dropdown-icon="more_horiz">
              <q-list>
                <q-item
                  clickable
                  v-close-popup
                  :to="'/customer/edit/' + val.id"
                >
                  <q-item-section>
                    <q-item-label>Edit</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item
                  clickable
                  v-close-popup
                  :to="'/customer/history/' + val.id"
                >
                  <q-item-section>
                    <q-item-label>Visit History</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <q-dialog v-model="showFilter" position="bottom" persistent>
      <q-card style="min-width:300px;">
        <q-card-section class="row justify-between items-center">
          <a class="text-h6">Filter :</a>
          <q-btn flat dense :ripple="false" icon="close" v-close-popup></q-btn>
        </q-card-section>
        <q-card-section class="column q-gutter-sm">
          <div class="column q-gutter-xs">
            <label class="text-bold"> Tipe Customer :</label>
            <q-select
              outlined
              :options="data.type"
              v-model="tempFilter.selTipe"
              multiple
            >
              <template
                v-slot:option="{ itemProps, opt, selected, toggleOption }"
              >
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt" />
                  </q-item-section>
                  <q-item-section side>
                    <q-toggle
                      :model-value="selected"
                      @update:model-value="toggleOption(opt)"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold"> Application :</label>
            <q-select
              outlined
              :options="data.application"
              v-model="tempFilter.selApplication"
              multiple
            >
              <template
                v-slot:option="{ itemProps, opt, selected, toggleOption }"
              >
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt" />
                  </q-item-section>
                  <q-item-section side>
                    <q-toggle
                      :model-value="selected"
                      @update:model-value="toggleOption(opt)"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="column q-gutter-xs">
            <label class="text-bold"> Kota :</label>
            <q-select
              outlined
              :options="data.city"
              v-model="tempFilter.selCity"
              multiple
            >
              <template
                v-slot:option="{ itemProps, opt, selected, toggleOption }"
              >
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label v-html="opt" />
                  </q-item-section>
                  <q-item-section side>
                    <q-toggle
                      :model-value="selected"
                      @update:model-value="toggleOption(opt)"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="Reset"
            @click="
              options.selTipe = [];
              options.selCity = [];
              options.selApplication = [];
              filteringCostumer();
            "
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            label="Apply"
            @click="
              options.selTipe = tempFilter.selTipe;
              options.selCity = tempFilter.selCity;
              options.selApplication = tempFilter.selApplication;
              filteringCostumer();
            "
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from "vue";
import useCustomer from "./useCustomer";
export default {
  setup() {
    const {
      getCustomer,
      getApplication,
      getType,
      getCity,
      data,
      route,
      router,
      $q,
    } = useCustomer();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getApplication();
        await getCity();
        await getType();
        await getCustomer(route.params.id, 0);

        if (route.params.filter) {
          let filter = JSON.parse(route.params.filter);
          options.searchTerm = filter.searchTerm;
          options.selTipe = filter.selTipe;
          options.selCity = filter.selCity;
          options.selApplication = filter.selApplication;
        }

        filteringCostumer();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    // filter property area
    let tempFilter = ref({});

    let options = reactive({
      searchTerm: "",
      selTipe: [],
      selCity: [],
      selApplication: [],
    });

    //router replace area

    watch([options], (newValues, prevValues) => {
      let routeFilter = JSON.stringify(newValues[0]);
      router.replace({
        path: `/customer/list/${route.params.id}/${routeFilter}`,
      });
    });

    //filtering area
    let filteredCostumer = ref([]);
    let filteringCostumer = () => {
      let customer = JSON.parse(JSON.stringify(data.customer));

      if (options.searchTerm != "") {
        customer = customer.filter((val) => {
          return (
            val.name.toLowerCase().indexOf(options.searchTerm.toLowerCase()) !=
            -1
          );
        });
      }

      if (options.selTipe.length > 0) {
        let temp = [];
        for (let tipe of options.selTipe) {
          temp = temp.concat(
            customer.filter((val) => {
              return val.type == tipe;
            })
          );
        }
        customer = temp;
      }

      if (options.selApplication.length > 0) {
        let temp = [];
        for (let application of options.selApplication) {
          temp = temp.concat(
            customer.filter((val) => {
              return val.application == application;
            })
          );
        }
        customer = temp;
      }

      if (options.selCity.length > 0) {
        let temp = [];
        for (let city of options.selCity) {
          temp = temp.concat(
            customer.filter((val) => {
              return val.city == city;
            })
          );
        }
        customer = temp;
      }

      filteredCostumer.value = customer;
    };

    return {
      getCustomer,
      showFilter: ref(false),
      data,
      filteredCostumer,
      options,
      filteringCostumer,
      tempFilter,
    };
  },
};
</script>
